@import "src/styles/LNQglobalVars";

.text {
  font-size: 14px;
  line-height: normal;
  color: $black;
  margin: 30px 0;
  text-align: left;
}

.helperText {
  font-size: 14px;
  line-height: 0%;
  color: $grey_8c;
  margin: 16px 0;
  text-align: left;
}

.bottom {
  position: absolute;
  bottom: 50px;
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
}

.forgotPassword {
  margin: -24px 0 18px;
  text-align: right;
  font-size: 12px;
  line-height: 2;
  color: $blue_10;
}

.error {
  color: #f5222d;
}
